.checkout-container {
  background-color: white;
}

@media only screen and (max-width: 899px) {
  .checkout-container {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .checkout-container {
    width: 640px;
    border-radius: 15px;
    float: right;
  }
}

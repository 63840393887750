.hosted-field {
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    border-style: solid;
    border-width: 1px;
    padding: 0.75em;
    height: 32px;
}

#card-payment-form label[for="expiration-date"], #card-payment-form label[for="cvv"] {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
}


#card-payment-form label[for="expiration-date"]{
    margin-left: 1em;
}

#card-payment-form label[for="cvv"] {
    margin-left: 1.5em;
}

.braintree-hosted-fields-focused {
    color: #495057;
    background-color: #fff;
    border-color: #1976d2;
    border-width: 2px;
    border-style: solid;
}

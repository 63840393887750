@media only screen and (max-width: 899px) {
    .checkout {
        flex-direction: column;
        display:flex;
        justify-content: center;
    }
}

@media only screen and (min-width: 900px) {
    .checkout {
        display:flex;
        flex-direction: row;
        gap: 2em;
        justify-content: center;
        padding-bottom: 2em;
    }
}
.apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
}

.apple-pay-button-buy {
    -apple-pay-button-type: buy;
}

.apple-pay-button-checkout {
    -apple-pay-button-type: check-out;
}

.apple-pay-button-black {
    -apple-pay-button-style: black;
}

.apple-pay-button-white {
    -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
}